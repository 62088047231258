import React from 'react'
import { SimpleMenu } from '../components/atoms'
import Layout from '../components/Layout'
const gallery = () => {
  return (
    <Layout>
      <SimpleMenu />
      gallery page
    </Layout>
  )
}

export default gallery
